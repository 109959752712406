import React, { createContext, ReactComponentElement, ReactNode, useState } from 'react';


interface PageContextProps {
  itemId: string;
  selectItem: React.Dispatch<React.SetStateAction<string>>;
}

const PageContext = createContext<PageContextProps | undefined>(undefined);

const PageProvider = ({ children }: {children: ReactNode}) => {
  const [itemId, selectItem] = useState('');

  return (
    <PageContext.Provider value={{ itemId, selectItem }}>
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider }