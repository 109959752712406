import { useContext, useEffect, useState } from "react";
import { PageContext } from "./PageContext";
import { Chip, Switch, Container, Grid } from "@mantine/core";

interface CraftAction {
  cost: number;
  source: number;
  picked: boolean;
  last_seen: number | undefined;
  recipe_name: string | undefined;
  recipe_id: number | undefined;
  targets: CraftTarget[] | null;
}

interface CraftTarget {
  item_id: number;
  item_name: string;
  price: number;
  decision: string;
  actions: CraftAction[];
}


const Craft = () => {
  const { itemId } = useContext(PageContext)!;
  const [ data, setData ] = useState<CraftTarget | undefined>(undefined);

  const fetchData = async (url: string) => {  
    try {
      const response = await fetch(url, 
        { headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok [${response.status}] [${response.statusText}] [${response.url}]`);
      }
      const data = await response.json();

      setData(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
    //   setLoading(false);
    }
  }

  useEffect(() => {
    itemId && fetchData(`${process.env.REACT_APP_API_URL}/api/craft?item=${itemId}`)
  }, [itemId])

  const printAction = (action: CraftAction) => {
    return (
      <span style={action.picked ? { backgroundColor: 'rgba(0,0,0,0.2'} : {}}>
        Action: {action.source === 1 ? 'Buy from AH' : `Craft ${action.recipe_name}`}, it costs {action.cost}g
      </span>
    )
  }

  const printTarget = (target: CraftTarget | undefined) =>{
    if (!target || !target.actions) {
      return null;
    }
    return (
      <div style={{marginLeft: '10px', border: "1px solid black"}}>
      <span>{target.item_name}: total {target.price}g, You should: {target.decision}</span>
      {
        target.actions.map((action, index) => {
          return (
            <div hidden={false}>
              {printAction(action)}
              {action.targets && action.targets.map((target, _) => {
                return (<div>{printTarget(target)}</div>)
              })}
            </div>
          )
        })
      }
      </div>
    )
  }

  return (
    <>
      {/* {loading && <Skeleton h={500} visible={loading}></Skeleton>} */}
      <h1>Hello</h1>
      {printTarget(data)}
    </>
  )
}

export { Craft }
