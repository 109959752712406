import { useRef, useState, useContext } from 'react';
import { Combobox, Loader, TextInput, useCombobox, Image, Text, Group } from '@mantine/core';
import { PageContext } from './PageContext';
import classes from './global.module.css';

interface Item {
  id: string;
  name: string;
  media_id: string;
  quality: string;
}

export function AsyncAutocomplete() {

  const fetchData = async (url: string, signal: AbortSignal) => {
    try {
      const response = await fetch(url, 
        { headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        signal,
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok [${response.status}] [${response.statusText}] [${response.url}]`);
      }
      const data = await response.json();
      setData(data);
      setEmpty(data.length === 0);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const itemById = (id: string): Item | undefined => {
    return data?.find((item) => item.id === id);
  }

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Item[] | null>(null);
  const [value, setValue] = useState<string>('');
  const [empty, setEmpty] = useState(false);
  const abortController = useRef<AbortController>();

  const fetchOptions = (query: string) => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    setLoading(true);

    fetchData(`${process.env.REACT_APP_API_URL}/api/items?name=${query}`, abortController.current.signal);
    abortController.current = undefined;
  };

  const options = (data || [])
    .map((item) => (
    <Combobox.Option value={item.name} key={item.id} >
      <Group gap="sm">
          {/* eslint-disable-next-line */}
          <a href='javascript:void(0)' data-wowhead={`item=${item.id}&domain=cata`}> 
            <Image src={`/icons/${item.media_id}.jpg`} radius="md" height={36} className={classes[`${item.quality.toLowerCase()}-border`]} />
          </a>
        <Text>{item.name}</Text>
      </Group>
    </Combobox.Option>
  ));

  const { itemId, selectItem } = useContext(PageContext)!;

  const leftSection = () => {
    let item = itemById(itemId);
    if (item) {
      return (
        /* eslint-disable-next-line */
        <a href="javascript:void(0)" data-wowhead={`item=${item.id}&domain=cata`}>
          <Image radius="md" src={`/icons/${item.media_id}.jpg`} width={20} height={20} className={classes[`${item.quality.toLowerCase()}-border`]}/>
        </a>
      )
    }
  }

  return (
    <Combobox
      onOptionSubmit={(optionValue: string) => {
        let item = data && data.find((item) => item.name === optionValue);

        item && selectItem(item.id);

        setValue(optionValue);
        combobox.closeDropdown();
      }}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          label="Pick value or type anything"
          placeholder="Search items"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            fetchOptions(event.currentTarget.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            //combobox.openDropdown();
            if (data === null) {
              fetchOptions(value);
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={loading && <Loader size={18} />}
          leftSection={leftSection()}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>No results found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}