import '@mantine/core/styles.css';
import { Container, MantineProvider, Grid } from '@mantine/core';
import { AsyncAutocomplete } from './AsyncAutocomplete';
import { PageProvider } from './PageContext';
import { ItemChart } from './ItemChart';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Craft } from './Craft';

const App = () => {
  return (
    <Router>
      <PageProvider>
        <MantineProvider forceColorScheme='light'>
          <header>
            <Container size="xl">
                <h1>Mirage Raceway EU</h1>
                <Link to="/">Item price</Link>&nbsp;
                <Link to="/craft">Craft</Link>
                <Grid>
                  <Grid.Col span={8}><AsyncAutocomplete></AsyncAutocomplete></Grid.Col>
                </Grid>
                <Grid m={10}>
                  <Grid.Col>
                    <Routes>
                      <Route path="/" element={<ItemChart/>}/>
                      <Route path="/craft" element={<Craft/>}/>
                    </Routes>
                  </Grid.Col>
                </Grid>
            </Container>
          </header>
        </MantineProvider>
      </PageProvider>
    </Router>
  );
}

export default App;
