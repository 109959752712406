import { useEffect, useRef } from "react";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';

interface ItemChartData {
  hour: number;
  mean: number;
  upperMean: number;
  lowerMean: number;
  count: number;
  p25: number;
  min: number;
}

const PriceChart = ({data} : {data: ItemChartData[]}) => {
    const chart = useRef(null);

    useEffect(() => {
      if (chart.current) {
        let chartStatus = Chart.getChart(chart.current)
        if (chartStatus !== undefined) {
            chartStatus.destroy();
        }

        new Chart(chart.current, {
          type: 'line',
          options: {
            animation: false,
            elements: {
              line: {
                borderWidth: 1,
                tension: 0.3,
              },
              point: {
                radius: 2,
              },
            },
            interaction: {
              intersect: false,
              mode: 'index',
            },
            scales: {
              x: {
                type: 'timeseries',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    hour: 'MM-dd HH'
                  }
                }
              },
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                suggestedMin: 0,
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                suggestedMin: 0,
              },
            },
            plugins: {
              legend: {
                position: 'right',
                // labels: {
                //   filter: function(item, chart) {
                //     if (!item.datasetIndex) {
                //       return true
                //     }
                //     return item.datasetIndex < 3
                //   },
                //   usePointStyle: true,
                //   pointStyle: 'dash',
                // },
                // onClick: function(e, legendItem, legend) {
                //   const index = legendItem.datasetIndex;
                //   if (index === undefined) {
                //     return
                //   }

                //   const ci = legend.chart;
                //   if (ci.isDatasetVisible(index)) {
                //       if (index === 1) {
                //         ci.hide(3);
                //         ci.hide(4);
                //       }
                //       ci.hide(index);
                //       legendItem.hidden = true;
                //   } else {
                //       ci.show(index);
                //       if (index === 1) {
                //         ci.show(3);
                //         ci.show(4);
                //       }
                //       legendItem.hidden = false;
                //   }
                // }
              },
              title: {
                text: 'Price Chart',
                  display: true,
                }
              },
            },
            data: {
              datasets: [
                {
                  label: 'Upper Mean',
                  data: data.map((item) => ({x: item.hour, y: item.upperMean})),
                  fill: '+1',
                  yAxisID: 'y',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                {
                  label: 'Mean',
                  data: data.map((item) => ({x: item.hour, y: item.mean})),
                  yAxisID: 'y',
                  borderColor: '#ea5545',
                  backgroundColor: '#ea5545',
                },
                {
                  label: 'Lower Mean',
                  data: data.map((item) => ({x: item.hour, y: item.lowerMean})),
                  fill: '-1',
                  yAxisID: 'y',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                {
                  label: 'p25',
                  data: data.map((item) => ({x: item.hour, y: item.p25})),
                  yAxisID: 'y',
                  backgroundColor: '#ef9b20',
                  borderColor: '#ef9b20',
                },
                {
                  label: 'Min',
                  data: data.map((item) => ({x: item.hour, y: item.min})),
                  yAxisID: 'y',
                  backgroundColor: '#87bc45',
                  borderColor: '#87bc45',
                },
                {
                  label: 'Quantity',
                  data: data.map((item) => ({x: item.hour, y: item.count})),
                  yAxisID: 'y1',
                  backgroundColor: '#b33dc6',
                  borderColor: '#b33dc6',
                },
              ],
            }
        });
      }   
    }, [data])

    return (
      <canvas ref={chart}></canvas>
    )
};

export { PriceChart }
export type { ItemChartData }