import { useContext, useEffect, useState } from "react";
import { PageContext } from "./PageContext";
import { Skeleton, Switch, Container, Grid } from "@mantine/core";
import { PriceChart, ItemChartData } from "./PriceChart";


const ItemChart = () => {
  const { itemId } = useContext(PageContext)!;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ItemChartData[]>([]);
  const [displayAll, setDisplayAll] = useState(true);

  const fetchData = async (url: string) => {  
    try {
      const response = await fetch(url, 
        { headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok [${response.status}] [${response.statusText}] [${response.url}]`);
      }
      const data = await response.json();

      console.log(data);

      setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let stat = 3;
    if (!displayAll) {
      stat = 0;
    }
    itemId && fetchData(`${process.env.REACT_APP_API_URL}/api/chart?stat=${stat}&item=${itemId}`)
  }, [itemId, displayAll])

  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <Switch
            checked={displayAll}
            onChange={(event) => setDisplayAll(event.currentTarget.checked)}
            label="Display all"
          />
        </Grid.Col>
      </Grid>
      {loading && <Skeleton h={500} visible={loading}></Skeleton>}
      {!loading && <PriceChart data={data} />}
    </>
  )
}

export { ItemChart }
